export class AddBreadcrumbTextInSearch {
  constructor(el) {
    this.el = document.querySelector(el);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.el) {
      return;
    }

    if (this.el.innerHTML.match(/検索結果/)) {
      const postType = getParam("post_type");
      let name;
      switch (postType) {
        case "works":
          name = "実績";
          break;
        case "product":
          name = "商品";
          break;
        case "news":
          name = "ニュース";
          break;
      }
      this.el.innerHTML = this.el.innerHTML.replace(
        "検索結果",
        `検索結果 &#039;${name}&#039;`
      );
    }
  }
}

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
