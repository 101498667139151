import { Gnavi } from "./Gnavi";
import { Slider, ItemsSlider, DoubleSlider } from "./Slider";
import { OpenSubmenuSp } from "./OpenSubmenuSp";
import { StoreCounter } from "./StoreCounter";
import { SmoothScroll } from "./SmoothScroll";
import { SelectAreaScroll } from "./SelectAreaScroll";
import { OpenSearchForm } from "./OpenSearchForm";
import { FilteringGenre } from "./FilteringGenre";
import { Accordion } from "./Accrodion";
import { EstimateToContact } from "./EstimateToContact";
import { AddBreadcrumbTextInSearch } from "./AddBreadcrumbTextInSearch";
import { RemoveBlankArea } from "./RemoveBlankArea";

photoswipeSimplify.init();

// global
new Gnavi(".js-gnavi-trigger", ".js-gnavi-container");
new OpenSubmenuSp(".js-open-submenu-trigger");
new OpenSearchForm(".js-searchform-trigger", ".js-searchform-body");
new SmoothScroll('a[href^="#"]');

// breadcrumb
new AddBreadcrumbTextInSearch(".search.current-item");

// slider
new Slider(".js-top-slider", {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  loop: true,
  speed: 1000,
  slidesPerView: "auto",
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  breakpoints: {
    1024: {
      slidesPerView: 1.1
    }
  }
});

new Slider(".js-bnr-slider", {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  loop: true,
  speed: 1000,
  centeredSlides: true,
  centerMode: true,
  slidesPerView: "auto",
  spaceBetween: 20,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  breakpoints: {
    480: {
      slidesPerView: 1.25,
      spaceBetween: 10
    }
  }
});

const itemSlideArgs = {
  slidesPerView: 4,
  spaceBetween: 16,
  slidesPerGroup: 4,
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 10,
      slidesPerGroup: 1
    }
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
  //watchOverflow: true
};

new ItemsSlider(".js-item-slider", itemSlideArgs);

new Slider(".js-single-case-slider", {
  //width: 180,
  slidesPerView: 3,
  spaceBetween: 9,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 10
    }
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
});

new DoubleSlider(
  ".js-post-slider",
  ".js-post-subslider",
  {
    loop: true,
    speed: 1000,
    disableOnInteraction: false
  },
  {
    spaceBetween: 2,
    slidesPerView: 3,
    direction: "vertical",
    //loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      1024: {
        slidesPerView: 1.5,
        spaceBetween: 2,
        direction: "horizontal"
      }
    }
  }
);

// top
new StoreCounter(".js-store-counter");

// office sp
new SelectAreaScroll('[name="select-area"]');

// faq
new FilteringGenre(".js-select-genre", '[name="select-genre"]', ".js-area");
new Accordion(".js-accordion");

//new ShowScrollHint(".js-scroll-hint");

new EstimateToContact(
  ".js-estimate-list__item",
  ".js-estimate-procedure__to-contact-btn"
);

new RemoveBlankArea(
  ".p-business-office, .p-single__office-area, .p-office-area"
);

(function($) {
  $(document).ready(function() {});
})(jQuery);

window.addEventListener("DOMContentLoaded", function() {
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links, function(link) {
    if (link.hasAttribute("rel") === false) {
      link.setAttribute("rel", "noopener");
    }
  });

  //hmb

  objectFitImages(".ofi");

  function linkInSite() {
    const hash = location.hash;
    if (hash !== "") {
      window.scrollBy(0, -100);
    }
  }
  window.addEventListener("load", linkInSite);
});

window.addEventListener("load", function() {
  //const loading = document.querySelector("#loading");
  //loading.classList.add("finished");
});

/******************
 *
 * add tel
 *
 * ***************/

/**************
 *
 * parallax
 *
 * ********** */

function parallax() {
  const parallax = document.querySelectorAll(".js-parallax");

  const options = {
    root: null,
    rootMargin: "100px  0px -100px",
    transhold: 0
  };

  const action = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("is-active");
      } else {
        entry.target.classList.remove("is-active");
      }
    });
  };

  const observer = new IntersectionObserver(action, options);

  [...parallax].forEach(elm => {
    observer.observe(elm);
  });
}
parallax();
