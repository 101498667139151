export class RemoveBlankArea {
  constructor(blocks) {
    this.blocks = document.querySelectorAll(blocks);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.blocks) {
      return;
    }

    [...this.blocks].forEach(bl => {
      const articles = bl.querySelectorAll(".p-office-article");

      if (!articles.length) {
        bl.style.display = "none";
      }
    });
  }
}
