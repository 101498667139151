export class FilteringGenre {
  constructor(triggers, select, targets) {
    this.triggers = document.querySelectorAll(triggers);
    this.select = document.querySelector(select);
    this.targets = document.querySelectorAll(targets);

    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.triggers || !this.select || !this.targets) {
      return;
    }

    const param = getParam("faq");
    this.watchParam(param);

    [...this.triggers].forEach(trigger => {
      trigger.addEventListener("click", e => {
        e.preventDefault();
        const targetData = trigger.dataset.trigger;
        // if (targetData === "common") {
        //   this.whenCommonSelected();
        // } else {
        [...this.triggers].forEach(each => {
          each.classList.remove("is-selected");
        });
        e.target.classList.add("is-selected");
        this.hideAndShow(targetData);
        //}
      });
    });

    this.select.addEventListener("change", e => {
      e.preventDefault();
      const targetData = e.target.value;

      if (targetData === "common") {
        this.whenCommonSelected();
      } else {
        this.hideAndShow(targetData);

        [...this.triggers].forEach(each => {
          each.classList.remove("is-selected");
        });

        document
          .querySelector(`[data-trigger="${targetData}"]`)
          .classList.add("is-selected");
      }
    });
  }

  watchParam(param) {
    if (param) {
      [...this.targets].forEach(target => {
        target.classList.add("is-hide");
      });
      const trigger = document.querySelector(`[data-trigger="${param}"]`);
      if (trigger) {
        document
          .querySelector(`[data-trigger="${param}"]`)
          .classList.add("is-selected");
      }

      const target = document.querySelector(`[data-target="${param}"]`);
      if (target) {
        document
          .querySelector(`[data-target="${param}"]`)
          .classList.remove("is-hide");
      }

      this.select.value = param;
    } else {
      this.hideAndShow();
      document
        .querySelector('[data-trigger="common"]')
        .classList.add("is-selected");
      document
        .querySelector('[data-target="common"]')
        .classList.remove("is-hide");
    }
  }

  hideAndShow(targetData = "common") {
    [...this.targets].forEach(target => {
      target.classList.add("is-hide");
      if (target.dataset.target === targetData) {
        target.classList.remove("is-hide");
      }
    });
  }

  whenCommonSelected() {
    [...this.triggers].forEach(each => {
      each.classList.remove("is-selected");
    });

    document
      .querySelector('[data-trigger="common"]')
      .classList.add("is-selected");

    this.hideAndShow();
  }
}

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
