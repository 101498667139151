//import Swiper from "swiper/bundle";

export class Slider {
  constructor(els, options = {}) {
    this.els = document.querySelectorAll(els);
    this.options = options;
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }
  _init() {
    if (!this.els) {
      return;
    }
    [...this.els].forEach(el => {
      new Swiper(el, this.options);
    });
  }
}

export class ItemsSlider {
  constructor(els, options = {}) {
    this.els = document.querySelectorAll(els);
    this.options = options;
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }
  _init() {
    if (!this.els) {
      return;
    }
    [...this.els].forEach(el => {
      const slidesLength = el.querySelectorAll(".swiper-slide").length;

      if (slidesLength > 3) {
        new Swiper(el, { loop: true, ...this.options });
      } else {
        new Swiper(el, this.options);
      }
    });
  }
}

export class DoubleSlider {
  constructor(main, sub, option1 = {}, option2 = {}) {
    this.main = document.querySelector(main);
    this.sub = document.querySelector(sub);
    this.option1 = option1;
    this.option2 = option2;
    window.addEventListener("load", this._init.bind(this));
  }

  _init() {
    if (!this.main && !this.sub) {
      return;
    }

    const subSlider = new Swiper(this.sub, this.option2);
    const images = document.querySelectorAll(".p-post-sliders__main img");
    const subImages = document.querySelectorAll(".p-post-sliders__sub img");

    [...images].forEach(img => {
      this.imageResize(img);
    });

    [...subImages].forEach(img => {
      this.imageResize(img);
    });

    const mainOption = { ...this.option1, thumbs: { swiper: subSlider } };
    new Swiper(this.main, mainOption);
  }

  imageResize(img) {
    const w = img.naturalWidth;
    const h = img.naturalHeight;

    if (h / w > 0.66) {
      img.classList.add("img-in-slider-height-longer");
    } else {
      img.classList.add("img-in-slider-width-longer");
    }
  }
}
