export class OpenSubmenuSp {
  constructor(triggers) {
    this.triggers = document.querySelectorAll(triggers);
    //this.targets = document.querySelectorAll(targets);

    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.triggers) {
      return;
    }
    this._action();
  }

  _action() {
    [...this.triggers].forEach(el => {
      el.addEventListener("click", e => {
        if (window.innerWidth < 1024) {
          console.log(window.innerWidth);
          e.preventDefault();
          const target = el.nextElementSibling;
          target.classList.toggle("js-active");
        }
      });
    });
  }
}
