import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();
export class SmoothScroll {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }

  _init() {
    if (!this.els) {
      return;
    }
    [...this.els].forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        const targetId = el.hash;
        const tgtPosition =
          document.querySelector(targetId).getBoundingClientRect().top +
          window.pageYOffset;
        let headerHeight;
        if (window.innerWidth > 1024) {
          headerHeight = 150;
        } else {
          headerHeight = 70;
        }

        const scrollAmount = tgtPosition - headerHeight;

        window.scrollTo({ top: scrollAmount, behavior: "smooth" });
      });
    });
  }
}
