export class OpenSearchForm {
  constructor(triggers, form) {
    this.triggers = document.querySelectorAll(triggers);
    this.form = document.querySelector(form);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.triggers || !this.form) {
      return;
    }

    [...this.triggers].forEach(trigger => {
      trigger.addEventListener("click", e => {
        if (window.innerWidth < 1024) {
          e.preventDefault();
          this.form.classList.toggle("js-open");
        }
      });
      trigger.addEventListener("mouseenter", e => {
        if (window.innerWidth >= 1024) {
          e.preventDefault();
          this.form.classList.add("js-open");
        }
      });

      this.form.addEventListener("mouseleave", e => {
        if (window.innerWidth >= 1024) {
          e.preventDefault();
          e.target.classList.remove("js-open");
        }
      });
    });
  }
}
