import gsap from "gsap";

export class Accordion {
  constructor(accordions) {
    this.accordions = document.querySelectorAll(accordions);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.accordions) {
      return;
    }
    [...this.accordions].forEach(accordion => {
      const trigger = accordion.querySelector(".js-accordion__trigger");
      const content = accordion.querySelector(".js-accordion__content");
      const triggerChildren = trigger.querySelectorAll("*");
      // content.style.height = "0px";
      // content.style.overflow = "hidden";
      trigger.addEventListener("click", e => {
        e.preventDefault();
        if (e.target.classList.contains("is-active")) {
          e.target.classList.remove("is-active");
          gsap.to(content, { duration: 0.1, height: 0 });
        } else {
          e.target.classList.add("is-active");
          gsap.to(content, { duration: 0.1, height: "auto" });
        }
      });

      [...triggerChildren].forEach(child => {
        child.style.pointerEvents = "none";
      });
    });
  }
}
