export class Gnavi {
  constructor(humb, navi) {
    this.humb = document.querySelector(humb);
    this.navi = document.querySelector(navi);

    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.humb || !this.navi) {
      return;
    }
    this._action();
  }

  _action() {
    this.humb.addEventListener("click", e => {
      e.preventDefault();
      document.body.classList.toggle("js-gnavi-open");
    });
  }
}

// new Gnavi(".js-gnavi-trigger", ".js-gnavi-container");
