export class EstimateToContact {
  constructor(targets, toContact) {
    this.targets = document.querySelectorAll(targets);
    this.toContact = document.querySelector(toContact);
    this.items = [];
    this.param = null;

    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.targets || !this.toContact) {
      return;
    }

    this.toContact.addEventListener("click", e => {
      // e.preventDefault();
      // [...this.targets].forEach(target => {
      //   const data = target.dataset.productid;
      //   this.items.push(data);
      // });
      // if (this.items.length > 0) {
      //   this.param = this.items.join("_");
      //   const path = `${this.toContact.href}${this.param}`;
      //   location.href = path;
      // } else {
      //   alert("商品を追加して下さい");
      // }
    });
  }
}
