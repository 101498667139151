export class StoreCounter {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.els) {
      return;
    }

    [...this.els].forEach(el => {
      const watcherDirect = el.querySelector(".js-store-counter__watch-direct");
      const watcherFc = el.querySelector(".js-store-counter__watch-fc");

      const targetDirect = el.querySelectorAll(
        ".js-store-counter__target-direct li"
      );
      const targetFc = el.querySelectorAll(".js-store-counter__target-fc li");

      watcherDirect.textContent = targetDirect.length;
      watcherFc.textContent = targetFc.length;
    });

    this._removeSubheading();
  }

  _removeSubheading() {
    const subheadings = document.querySelectorAll(
      ".js-remove-subheading-if-list-blank"
    );

    if (!subheadings) {
      return;
    }
    [...subheadings].forEach(el => {
      const nextList = el.nextElementSibling;
      if (nextList.children.length === 0) {
        el.parentNode.removeChild(el);
      }
    });
  }
}
