import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export class SelectAreaScroll {
  constructor(el) {
    this.el = document.querySelector(el);
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }

  _init() {
    if (!this.el) {
      return;
    }

    this.el.addEventListener("change", e => {
      e.preventDefault();

      const tgtPosition =
        document.getElementById(e.target.value).getBoundingClientRect().top +
        window.pageYOffset;
      let headerHeight;
      if (window.innerWidth > 1024) {
        headerHeight = 150;
      } else {
        headerHeight = 70;
      }

      const scrollAmount = tgtPosition - headerHeight;

      window.scrollTo({ top: scrollAmount, behavior: "smooth" });
    });
  }
}
